import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Autocomplete, Button, ButtonGroup, Checkbox, Grid, TextField, Tooltip } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditIcon from "@mui/icons-material/Edit";

const DisplayTable = ({
  value,
  filteredStandards,
  allStandards,
  uncertaintyMasterData,
  disciplineId,
  setState,
}) => {
  const [tableData, setTableData] = useState([]);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [newRowIndex, setNewRowIndex] = useState(-1);
  const [unusedStandards, setUnusedStandards] = useState([]);
  const [isShowAll, setIsShowAll] = useState(false);

  const updateMaster = (newMasterObject) => {
    newMasterObject = newMasterObject
      .map((e) =>
        Object.values(e)
          .map((e2, i) => (i == 1 ? e2.join(",") : e2))
          .join(":")
      )
      .join("|");
    let st = {};
    st.standardMasterIdArray = newMasterObject == "" ? null : newMasterObject;
    setState({ ...st });
  };

  const addRow = () => {
    setNewRowIndex(tableData.length);
    setEditingRowIndex(tableData.length);
    const newTableData = [
      ...tableData,
      { standardMasterId: null, uncertaintyFactors: [] },
    ];
    setTableData(newTableData);
    updateMaster(newTableData);
  };

  const deleteRow = (rowIndex) => {
    let newReadingRows = [...tableData];
    newReadingRows.splice(rowIndex, 1);
    setTableData(newReadingRows);
    updateMaster(newReadingRows);
  };

  const handleChange = (index, field, newValue) => {
    const updatedTableData = [...tableData];
    updatedTableData[index][field] = newValue;
    setTableData(updatedTableData);
    updateMaster(updatedTableData);
  };

  const filterUnusedStandards = (standards, usedStandardmasterIds) => {
    if (!usedStandardmasterIds)
      usedStandardmasterIds = tableData.map((e) => e.standardMasterId);
    const unusedStandards = standards.filter(
      (standard) => !usedStandardmasterIds.includes(standard.id.toString())
    );
    setUnusedStandards(unusedStandards);
  };

  useEffect(() => {
    if (value) {
      const usedStandardmasterIds = [];
      const parsedTableData = value.split("|").map((row) => {
        const [standardMasterId, uncertaintyFactors] = row.split(":");
        usedStandardmasterIds.push(standardMasterId);
        return {
          standardMasterId: standardMasterId || null,
          uncertaintyFactors: uncertaintyFactors?.split(",").map((e) => e),
        };
      });
      setTableData(parsedTableData);
      if (!isShowAll) {
        filterUnusedStandards(filteredStandards, usedStandardmasterIds);
      } else {
        filterUnusedStandards(allStandards, usedStandardmasterIds);
      }
    }
  }, [value, disciplineId, isShowAll]);

  const disabledCondition = () => {
    return (
      newRowIndex !== -1 &&
      newRowIndex === tableData.length - 1 &&
      (tableData?.[newRowIndex]?.standardMasterId === "" ||
        tableData?.[newRowIndex]?.standardMasterId === undefined ||
        tableData?.[newRowIndex]?.standardMasterId === null ||
        tableData?.[newRowIndex]?.uncertaintyFactors === "" ||
        tableData?.[newRowIndex]?.uncertaintyFactors === undefined ||
        tableData?.[newRowIndex]?.uncertaintyFactors === null ||
        tableData?.[newRowIndex]?.uncertaintyFactors?.length === 0)
    );
  };

  return (
    <div>
      <Grid container spacing={2} width="100%" justifyContent="space-between">
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Button
            id="instrumentlist_create_selectmasteridtoggle"
            variant="contained"
            size="small"
            onClick={() => {
              setIsShowAll(!isShowAll);
            }}
          >
            {isShowAll
              ? " standard Masters(All)"
              : "standard Masters(BY Discipline)"}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <Button
            id="instrumentlist_create_add_master"
            variant="contained"
            size="small"
            onClick={addRow}
            startIcon={<AddIcon />}
            disabled={disabledCondition()}
          >
            ADD Masters
          </Button>
        </Grid>
      </Grid>

      <br />
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <th>Standard Master ID</th>
            <th>Uncertainty Factors</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td width="400px" id="instrumentlist_create_selectmasterid">
                {editingRowIndex === rowIndex ? (
                  <Autocomplete
                    disabled={rowIndex !== newRowIndex}
                    fullWidth
                    id={`standard-master-autocomplete-${rowIndex}`}
                    size="small"
                    options={unusedStandards || []}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                      `${option.type == 1 ? "MI":"SI"} :: ${option.stId}: ${option.standardName}`
                    }
                    value={
                      allStandards.find(
                        (option) => option.id == row?.standardMasterId
                      ) || null
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{
                            marginRight: 8,
                            color:
                              new Date(option?.validUpto) < new Date()
                                ? "red"
                                : "black",
                          }}
                          checked={selected}
                        />

                        {/* {option.stId} <b>:</b> {option.standardName} */}
                        <span
                          style={{
                            color:
                              new Date(option?.validUpto) < new Date()
                                ? "red"
                                : "black",
                          }}
                        >
                         {option.type == 1 ? "MI":"SI"} <b> :: </b> {option.stId} <b>:</b> {option.standardName}
                        </span>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Standard Master"
                      />
                    )}
                    onChange={(event, value) => {
                      handleChange(
                        rowIndex,
                        "standardMasterId",
                        value ? value.id : null
                      );
                      // setState({
                      //   ...state,
                      //   selectedStandardMasterId: value ? value.id : null,
                      // });
                    }}
                  />
                ) : (
                  <div
                    style={{
                      color:
                        new Date(
                          allStandards?.find(
                            (option) => option.id == row?.standardMasterId
                          )?.validUpto
                        ) < new Date()
                          ? "red"
                          : "black",
                    }}
                  >
                    {allStandards.find(
                      (option) => option.id == row?.standardMasterId
                    )
                      ? `${
                        allStandards.find(
                          (option) => option.id == row?.standardMasterId
                        ).type == 1 ?"MI":"SI"
                      } :: ${
                          allStandards.find(
                            (option) => option.id == row?.standardMasterId
                          ).stId
                        } : ${
                          allStandards.find(
                            (option) => option.id == row?.standardMasterId
                          ).standardName
                        }`
                      : "Standard not found"}
                  </div>
                )}
              </td>
              <td id="instrumentlist_create_uncertaintyfact">
                {editingRowIndex === rowIndex ? (
                  <Autocomplete
                    multiple
                    fullWidth
                    id={`uncertainty-factors-autocomplete-${rowIndex}`}
                    size="small"
                    options={uncertaintyMasterData || []}
                    value={(row.uncertaintyFactors || []).reduce(
                      (selected, id) => {
                        const selectedOption = uncertaintyMasterData.find(
                          (item) => item.id == id
                        );
                        if (selectedOption) {
                          selected.push(selectedOption);
                        }
                        return selected;
                      },
                      []
                    )}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.name || ""}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.id} <b>:</b> {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Uncertainty Factors"
                      />
                    )}
                    onChange={(event, value) => {
                      handleChange(
                        rowIndex,
                        "uncertaintyFactors",
                        value ? value.map((item) => item.id) : []
                      );
                    }}
                  />
                ) : (
                  <div>
                    {row.uncertaintyFactors?.map((id) => {
                      const uncertaintyFactor = uncertaintyMasterData.find(
                        (item) => item.id == id
                      );
                      return uncertaintyFactor
                        ? `${uncertaintyFactor.name}, `
                        : null;
                    })}
                  </div>
                )}
              </td>
              <td style={{ minWidth: "80px",padding:"10px" }}>
                  <ButtonGroup size="small" aria-label="small button group">
                  <Tooltip title="Edit" placement="top-start">
                  <Button
                    id="instrumentlist_create_factedit"
                    style={{ color: "#1976d2", cursor: "pointer" }}
                    onClick={() => setEditingRowIndex(rowIndex)}
                    sx={
                      newRowIndex !== rowIndex
                        ? {
                            pointerEvents: disabledCondition() ? "none" : "",
                            cursor: disabledCondition() ? "not-allowed" : "",
                            opacity: disabledCondition() ? 0.5 : 1,
                          }
                        : {}
                    }
                      >
                        <EditIcon />
                      </Button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top-start">
                    <Button id="instrumentlist_create_factdelete"
                    style={{ color: "#dc3545", cursor: "pointer" }}
                    onClick={() => deleteRow(rowIndex)}>
                 <DeleteIcon/>
                    </Button>
                    </Tooltip>
                  </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DisplayTable;
