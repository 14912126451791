import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { Grid, TextField, useMediaQuery } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Typography, Modal } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { showComponent } from "../helper/helpers";
import EmailIcon from "@mui/icons-material/Email";
import TextEditor from "./textEditor";
import VerifiedIcon from "@mui/icons-material/Verified";
import DangerousIcon from "@mui/icons-material/Dangerous";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionIcon from "@mui/icons-material/Description";

import { updateSRFAnalytics } from "../../utils/analytics/srfAnalytics";
import { green, red, orange } from "@material-ui/core/colors";
import { checkDSStatusByCount } from "./countTester";
import { srfListGuide } from "./guide";
import { useSettings } from "./editSrf/fetchHandlers";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";

const getStatusColor = (status) =>
  status === "yes" ? green[500] : orange[500];

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");


const columns = [
  { id: "id", label: "ID", minWidth: 80 },
  { id: "customerName", label: "Client", minWidth: 40 },
  { id: "address", label: "Address", minWidth: 40 },
  {
    id: "serviceReqNumber",
    label: "Service Req Number",
    minWidth: 40,
    align: "left",
  },

  {
    id: "commitedDate",
    label: "Commited delivery Date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "issueDate",
    label: "Issue Date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "userName",
    label: "Created by",
    minWidth: 40,
    align: "left",
  },
];

const useStyles = makeStyles({
  timeline: {
    transform: "rotate(270deg)",
  },
  timelineContentContainer: {
    textAlign: "left",
  },
  timelineContent: {
    display: "inline-block",
    transform: "rotate(90deg)",
    textAlign: "center",
    minWidth: 50,
  },
  success: {
    color: "green",
  },
  danger: {
    color: "orange",
  },
});

function createData(
  id,
  customerName,
  address,
  serviceReqNumber,
  commitedDate,
  issueDate,
  clientId,
  userName,
  dcNumber,
  dcDate,
  entryDate,
  receiptDate
) {
  return {
    id,
    customerName,
    address,
    serviceReqNumber,
    commitedDate,
    issueDate,
    clientId,
    userName,
    dcNumber,
    dcDate,
    entryDate,
    receiptDate,
  };
}

export default function SrfsList() {
  const srfListGuides = srfListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [srfList, setSrfList] = React.useState([]);
  const [dBStatus, setDBStatus] = React.useState("");
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [userId, setUserId] = React.useState(localStorage.getItem("id"));
  const [branch, setBranch] = React.useState(localStorage.getItem("branch"));
  const validCompanies = localStorage.getItem("validClients");
  const [open, setOpen] = React.useState(false);
  const userName = localStorage.getItem("userName");
  const [selectedColumn, SetSelectedColumn] = React.useState([]);
  const [openTimeline, setOpenTimeline] = React.useState(false);
  const [srfRowId, setsrfRowId] = React.useState(null);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [companyName, setCompanyName] = React.useState("");
  const [srfNumber, setSrfNumber] = React.useState("");
  const [invoiceStatus, setInvoiceStatus] = React.useState("");
  const [srfAnalyticsData, setsrfAnalyticsData] = React.useState([]);
  const [pushIArray, setPushIArray] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [rowDaata, setRowDaata] = React.useState([]);
  const classes = useStyles();
  const [isCSRF, setIsCSRF] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [printmodalopen, setPrintModalOpen] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleOpenTimeline = (id, customerName, serviceReqNumber) => {
    //row.id,row.customerName,row.serviceReqNumber
    updateSRFAnalytics(id);
    setCompanyName(customerName);
    setSrfNumber(serviceReqNumber);
    setOpenTimeline(true);
    setsrfRowId(id);
  };

  const handleCloseimeline = () => {
    setOpenTimeline(false);
  };
  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);
  const handlePrintModalOpen = (id) => {
    setSelectedRowId(id);
    setPrintModalOpen(true);
  };
  const handlePrintModalClose = () => {
    setPrintModalOpen(false);
  };

  const [formData, setFormData] = React.useState({
    userEmail: "",
    clientName: userName ? userName.toUpperCase() : "",
    subject: "",
  });

  const [editorValue, setEditorValue] = React.useState(null);
  const [settings, fetchSettings] = useSettings({});

  const handleEditorChange = (value) => {
    setEditorValue(value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "and pr.companyName",
        "service Request Number": "and pl.serviceReqNumber",
      }[searchBy];
      whereConditions = ` ${colName} like '%${searchKey
        .replaceAll("/", "_")
        .replaceAll("#", "_")}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` pl.entryDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    whereConditions +=
      userType == 4 && branch && branch.length
        ? (whereConditions ? "  " : "") + `and pr.branch in (${branch})`
        : "";

    // whereConditions += (whereConditions ? " and " : "") + " pl.status = 1 ";

    if (userType == "2") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (pl.createdby=${userId})`
          : ` and (pl.createdby=${userId})`;
      // whereConditions +=
      //   whereConditions.length > 0 && validCompanies
      //     ? ` or pr.id in (${validCompanies}))`
      //     : ` )`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT pl.clientId, pr.companyName, pl.serviceReqNumber, pl.committedDeliveryDate, pl.receiptDate, pl.id, GROUP_CONCAT(a.address SEPARATOR ' || ') AS addresses, pl.entryDate, u.userName FROM srfs AS pl LEFT JOIN clients AS pr ON pl.clientId = pr.id LEFT JOIN users AS u ON u.id = pl.createdby LEFT JOIN addresses AS a ON FIND_IN_SET(a.id, REPLACE(REPLACE(CONCAT(',', pl.address, ','), ',', ',,'), ',,', ',')) > 0 WHERE pl.status = 1 ${whereConditions} GROUP BY pl.id order by pl.id DESC ${pagination_settings}`,
    };
    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM srfs AS pl LEFT JOIN clients AS pr ON pl.clientId = pr.id LEFT JOIN users AS u ON u.id = pl.createdby LEFT JOIN addresses AS a ON FIND_IN_SET(a.id, REPLACE(REPLACE(CONCAT(',', pl.address, ','), ',', ',,'), ',,', ',')) > 0 WHERE pl.status = 1 ${whereConditions}`;

    return data;
  };

  // api calls
  function getTotalRows() {
    // var data = {
    //   query:
    //     userType == "2"
    //       ? `select count(*) as no_of_rows from srfs where createdby=${userId}`
    //       : `select count(*) as no_of_rows from srfs`,
    // };
    let data = getQuery(true);
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0].no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "getTotalRows", "Srf List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      });
  }

  function getSrfsList() {
    let data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setSrfList([...res.data]);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "getSrfsList", "Srf List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const search = () => {
    getSrfsList();
  };
  function getSrfAnalytics() {
    const url = `${BASE_URL}dynamic`;

    const Payload = {
      query: `SELECT srfAnalytics.*, srfInstruments.instrumentId, srfInstruments.DUCID , srfInstruments.serialNumber  FROM srfAnalytics LEFT JOIN srfInstruments ON srfAnalytics.srfInstrumentId = srfInstruments.id WHERE srfAnalytics.srfId =${srfRowId}`,
    };
    axiosWithToken
      .post(url, Payload)
      .then((res) => {
        setsrfAnalyticsData(res.data);
      })
      .catch((err) => {
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "getSrfAnalytics", "Srf List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      });
  }
  const getInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((err) => {
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "getInstrumentsList", "Srf List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      });
  };
  const generatePushIArray = (instrumentArray) => {
    const newPushIArray = [];

    for (let i = 0; i < instrumentArray.length; i++) {
      if (instrumentArray[i].instrumentName) {
        newPushIArray.push({
          label:
            instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
        });
      }
    }

    return newPushIArray;
  };

  useEffect(() => {
    setPushIArray(generatePushIArray(instrumentArray));
  }, [instrumentArray]);

  useEffect(() => {
    getSrfAnalytics();
  }, [srfRowId]);

  useEffect(() => {
    checkDSStatusByCount(setDBStatus);
    getTotalRows();
    getInstrumentsList();
    fetchSettings(["CSRFS"]);
  }, []);

  useEffect(() => {
    if (settings["CSRFS"]?.value === "true") {
      setIsCSRF(true);
    }
  }, [settings]);

  useEffect(() => {
    getSrfsList();
  }, [rowsPerPage, page]);

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `srfInstruments?_where=(srfsId,eq,${id})&_fields=id`)
      .then((res) => {
        Promise.all([
          axiosWithToken.delete(url + `srfs/${id}`),
          ...res.data.map((d) =>
            axiosWithToken.delete(url + `datasheets/${d.id}`)
          ),
          ...res.data.map((d) =>
            axiosWithToken.delete(url + `certificates/${d.id}`)
          ),
          ...res.data.map((d) =>
            axiosWithToken.delete(url + `srfInstruments/${d.id}`)
          ),
        ])
          .then((res) => {
            toast("Srf deleted successfully!");
            setTimeout(refresh, 1000);
          })
          .catch((err) => {
            toast.error(
              <h6
                onClick={() =>
                  openErrorModal(err.message, "submitSiteDelete", "Srf List")
                }
              >
                <u>Error: {err.message}</u>
              </h6>
            );
          });
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  };

  const generateRowDaata = (srfList) => {
    const newRowDaata = [];

    for (let i = 0; i < srfList.length; i++) {
      newRowDaata.push(
        createData(
          srfList[i].id,
          srfList[i].companyName,
          srfList[i].addresses,
          srfList[i].serviceReqNumber,
          moment(srfList[i].committedDeliveryDate).format("DD-MM-YYYY"),
          moment(srfList[i].receiptDate).format("DD-MM-YYYY"),
          srfList[i].clientId,
          srfList[i].userName,
          srfList[i].receiptDate,
          srfList[i].dcNumber,
          moment(srfList[i].dcDate).format("DD-MM-YYYY"),
          moment(srfList[i].entryDate).format("DD-MM-YYYY")
        )
      );
    }

    return newRowDaata;
  };

  /////////////////////////////////// Mail ///////////////////////////////////////

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      let payload = {
        userEmail: formData.userEmail,
        clientName: formData.clientName,
        subject: formData.subject,
        description: editorValue,
      };

      axiosWithToken
        .post("http://localhost:5000/api/email", payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Email sent successfully !");
        })
        .catch((err) => {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "handleSubmit", "Srf List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        });

      handleClose();
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  const renderPrintModal = () => {
    return (
      <Modal
        open={printmodalopen}
        onClose={handlePrintModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            height: 250,
            bgcolor: "background.paper",
            border: "2px solid cadetblue",
            padding: "30px",
            textAlign: "center",
            borderRadius: "25px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "24px",
                flexDirection: "column",
              }}
            >
              <div style={{ flex: 1, width: "100%" }}>
                <span>Download SRF AND CSRF</span>
              </div>
              <div style={{ flexBasis: "50px" }}></div>
              <div style={{ flex: 1, width: "100%", display: "flex" }}>
                <div style={{ flex: 1, width: "100%" }}>
                  <Tooltip
                    title="Ignore Warning and adds Reading"
                    placement="top-start"
                  >
                    <Button
                      component={Link}
                      to={`/srf/viewSrf/${selectedRowId}`}
                      variant="contained"
                      size="small"
                      sx={{ m: 0, ml: 2 }}
                    >
                      SRF Print
                    </Button>
                  </Tooltip>
                </div>
                <div style={{ flex: 1, width: "100%" }}>
                  <Tooltip
                    title="Clear readings but keeps instrument"
                    placement="top-start"
                  >
                    <Button
                      component={Link}
                      to={`/srf/viewCsrf/${selectedRowId}`}
                      variant="contained"
                      size="small"
                      sx={{ m: 0, ml: 2 }}
                    >
                      CSRF Print
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const renderEmailModal = () => {
    let DescriptionValue = `<p><br></p><p>Thanks for your business with us. We have received your instruments against your challan No: for calibration Details</p><p><br></p><p>Job No:--${selectedColumn.serviceReqNumber},</p><p>Purchase Order No:-${selectedColumn.poNumber},</p><p>Receipt Date:- ${selectedColumn.receiptDate},</p><p>DC/RGP Number:- ${selectedColumn.dcNumber},</p><p><span style=\"color: rgb(102, 102, 102);\">DC/RGP Date:- ${selectedColumn.dcDate},</span></p><p><span style=\"color: rgb(102, 102, 102);\">Entry Date:- ${selectedColumn.entryDate},</span></p><p><span style=\"color: rgb(102, 102, 102);\">Committed Delivery Date:- ${selectedColumn.committedDeliveryDate},</span></p><p><br></p><p>Please feel free to contact us in case of any queries, Expecting your valuable order.</p><p><br></p><p>Kindly confirm the receipt of the mail While sending the Instrument send all accessories(cables, power chord etc) along with the instruments for calibration</p><p><br></p><p>Thanks &amp; Regards,</p><p><br></p><p>Er. Pravin Kashid</p><p>(Founder)</p><p>[LAB City]</p><p><br></p><p>&nbsp;Regd. Office: 129, Vindya Complex, Sector-11,</p><p>CBD Belapur Navi Mumbai, Maharashtra.</p><p>Contact: 9769847865</p><p>Website: <a href=\"https://bfiinfotech.co.in/\" rel=\"noopener noreferrer\" target=\"_blank\">bfiinfotech.co.in</a></p><p>&nbsp;[LAB City Is The Product Of BFI]</p>`;

    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 800,
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h5" mb={2}>
            Email Form
          </Typography>
          <TextField
            label="User Email"
            fullWidth
            name="userEmail"
            value={formData.userEmail}
            onChange={handleChange}
            mb={6}
            sx={{ marginBottom: "20px" }}
          />
          <TextField
            label="Client Name"
            fullWidth
            name="clientName"
            value={formData.clientName}
            onChange={handleChange}
            mb={6}
            sx={{ marginBottom: "20px" }}
          />
          <TextField
            label="Subject"
            fullWidth
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            mb={6}
            sx={{ marginBottom: "20px" }}
          />

          <TextEditor
            value={editorValue || DescriptionValue}
            onChange={handleEditorChange}
          />
          <Button
            sx={{ marginTop: "20px" }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Send
          </Button>
        </Box>
      </Modal>
    );
  };

  const renderTimeline = () => {
    //srfAnalyticsData
    function getStatusObject(progressPercent) {
      if (progressPercent === null) {
        return {
          inwordStatus: "no",
          dataSheetStatus: "no",
          approveStatus: "no",
          dispatchedStatus: "no",
        };
      }

      const inwordStatus = progressPercent >= 25 ? "yes" : "no";
      const dataSheetStatus = progressPercent >= 50 ? "yes" : "no";
      const approveStatus = progressPercent >= 75 ? "yes" : "no";
      const dispatchedStatus = progressPercent >= 90 ? "yes" : "no";

      return {
        inwordStatus,
        dataSheetStatus,
        approveStatus,
        dispatchedStatus,
      };
    }

    const statusObjects = srfAnalyticsData?.map((item) => ({
      id: item.id,
      srfId: item.srfId,
      srfInstrumentId: item.srfInstrumentId,
      status: getStatusObject(item.progressPercent),
      instrumentId: item.instrumentId,
      DUCID: item.DUCID,
      serialNumber: item.serialNumber,
    }));
    let data = {
      query: `SELECT CASE WHEN EXISTS (SELECT 1 FROM customInvoice WHERE serviceReqNumber LIKE '%\"${srfNumber}\"%') THEN 'yes' ELSE 'no' END AS Response`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setInvoiceStatus(res.data[0].Response);
      })
      .catch((err) => {
        console.log("InvoiceStatus data fetching error: ", err);
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "renderTimeline", "Srf List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      });

    return (
      <Modal open={openTimeline} onClose={handleCloseimeline}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 1100,
            height: 550,
            overflowY: "scroll",
          }}
        >
          <Button
            variant="contained"
            size="small"
            style={{ float: "right" }}
            onClick={() => setOpenTimeline(false)}
          >
            X
          </Button>
          <TableContainer component={Paper}>
            <Box>
              <Typography style={{ fontSize: "20px" }}>
                <Typography variant="span" style={{ fontWeight: "bold" }}>
                  Client:
                </Typography>
                &nbsp;&nbsp;
                <Typography variant="span">{companyName}</Typography>
              </Typography>
              <Typography style={{ fontSize: "20px" }}>
                <Typography variant="span" style={{ fontWeight: "bold" }}>
                  SRF Number:
                </Typography>
                &nbsp;&nbsp;<Typography variant="span">{srfNumber}</Typography>
              </Typography>
              <Typography style={{ fontSize: "20px" }}>
                <Typography variant="span" style={{ fontWeight: "bold" }}>
                  Invoice Status:
                </Typography>
                &nbsp;&nbsp;
                <Typography
                  variant="span"
                  style={{ color: getStatusColor(invoiceStatus) }}
                >
                  {invoiceStatus == "yes" ? (
                    <VerifiedIcon />
                  ) : (
                    <DangerousIcon />
                  )}
                </Typography>
              </Typography>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Instrument Name
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    DUCID
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Serial Number
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Inword Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    DataSheet Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Approve Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#1976d2" }}
                  >
                    Dispatched Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusObjects?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {item?.instrumentId
                        ? pushIArray
                            ?.filter(
                              (e) =>
                                e?.label?.split(",")[0] == item?.instrumentId
                            )?.[0]
                            ?.label.split(",")[1]
                        : ""}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {item?.DUCID}
                    </TableCell>
                    <TableCell>{item?.serialNumber}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.inwordStatus),
                      }}
                    >
                      {item.status.inwordStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.dataSheetStatus),
                      }}
                    >
                      {item.status.dataSheetStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.approveStatus),
                      }}
                    >
                      {item.status.approveStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: getStatusColor(item.status.dispatchedStatus),
                      }}
                    >
                      {item.status.dispatchedStatus == "yes" ? (
                        <VerifiedIcon />
                      ) : (
                        <DangerousIcon />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    );
  };

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 5px", marginBottom: "2px" }}
    >
      <Grid item xs={6} sm={6} md={2} lg={2} id="srflist-datefrom">
        <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
          <DatePicker
            slotProps={{
              textField: { size: "small", fullWidth: true },
            }}
            label="From"
            value={from ? new Date(from) : ""}
            inputFormat="dd/MM/yyyy"
            format="dd/MM/yyyy"
            onChange={(newValue) => setFrom(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2} id="srflist-dateto">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            slotProps={{
              textField: { size: "small", fullWidth: true },
            }}
            label="To"
            value={to ? new Date(to) : ""}
            inputFormat="dd/MM/yyyy"
            format="dd/MM/yyyy"
            onChange={(newValue) => setTo(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
            style={{ marginRight: "20px" }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={5} md={2} lg={2}>
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          size="small"
          id="srflist-searchby"
          options={[
            { key: "serviceReqNumber", label: "service Request Number" },
            { key: "companyName", label: "Client" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
          style={{ width: "100%" }}
          id="srflist-searchbytext"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} textAlign={"left"}>
        <Button
          id="srflist-searchbtn"
          variant="contained"
          size="small"
          onClick={() => {
            search();
            handleFilterClose();
          }}
        >
          <SearchIcon />
        </Button>
      </Grid>
    </Grid>
  );

  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  useEffect(() => {
    setRowDaata(generateRowDaata(srfList));
  }, [srfList]);

  return (
    <>
      {dBStatus === "sick" ? (
        <h1 style={{ color: "red" }}>
          {" "}
          Record count is mismatching.
          <br /> please contact the support team. Tel: +91 9769847865
        </h1>
      ) : null}
      {dBStatus !== "sick" && (
        <Box sx={{ margin: "0px 5px", marginTop: "-0.8rem" }}>
          {filterModal()}
          {renderEmailModal()}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Toolbar id="srflist-createsrfbtn" sx={{ padding: 0 }}>
              <Button
                variant="contained"
                size="small"
                component={Link}
                to="/srf/editSRF/0"
              >
                <b>create srf</b>
              </Button>
            </Toolbar>
            <Box sx={{ display: "flex", gap: "0rem" }}>
              <Button
                style={{ display: isMobile ? "block" : "none" }}
                onClick={handleFilterOpen}
              >
                <FilterAltIcon />
              </Button>
              <Tooltip title="User Guide" placement="top-start">
                <Button
                  onClick={() => {
                    srfListGuides.drive();
                  }}
                  style={{ marginRight: "5px" }}
                >
                  <HelpIcon />
                </Button>
              </Tooltip>
            </Box>
          </div>
          {!isMobile && filterSection()}
          <Box sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: isMobile ? "70vh" : "70vh" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                size="small"
                class="table-responsive"
                id="srflist-table"
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <b>{column.label}</b>
                      </TableCell>
                    ))}
                    <TableCell>
                      <b>Progress List</b>
                    </TableCell>
                    <TableCell>
                      <b>Actions</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowDaata
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            let value = row[column.id];
                            if (column.id == "id") {
                              value = index + 1;
                            }
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <Button
                              component={Link}
                              onClick={() =>
                                handleOpenTimeline(
                                  row.id,
                                  row.customerName,
                                  row.serviceReqNumber
                                )
                              }
                            >
                              Progress
                            </Button>
                          </TableCell>
                          <TableCell>
                            <ButtonGroup
                              size="small"
                              aria-label="small button group"
                            >
                              <Tooltip title="Edit SRF" placement="top-start">
                                <Button
                                  id="srflist-editsrf"
                                  component={Link}
                                  to={`/srf/editSrf/${row.id}`}
                                >
                                  <PreviewIcon />
                                </Button>
                              </Tooltip>

                              <Tooltip title="Send Email" placement="top-start">
                                <Button
                                  id="srflist-srfmail"
                                  onClick={() => {
                                    SetSelectedColumn(row);
                                    handleOpen();
                                  }}
                                >
                                  <EmailIcon />
                                </Button>
                              </Tooltip>

                              <Tooltip title="Dispatch" placement="top-start">
                                <Button
                                  id="srflist-srfdispatch"
                                  component={Link}
                                  to={`/srf/dispatch/${row.id}/${row.clientId}`}
                                >
                                  <SendTimeExtensionIcon />
                                </Button>
                              </Tooltip>

                              <Tooltip
                                title="Dispatch Status"
                                placement="top-start"
                              >
                                <Button
                                  id="srflist-srfdispatchstatus"
                                  component={Link}
                                  to={`/srf/DispatchStatus/${row.id}`}
                                >
                                  <DescriptionIcon />
                                </Button>
                              </Tooltip>

                              <Tooltip title="Print Srf" placement="top-start">
                                {isCSRF ? (
                                  <Button
                                    onClick={() => handlePrintModalOpen(row.id)}
                                  >
                                    <PrintIcon />
                                  </Button>
                                ) : (
                                  <Button
                                    id="srflist-printsrf"
                                    component={Link}
                                    to={`/srf/viewSrf/${row.id}`}
                                  >
                                    <PrintIcon />
                                  </Button>
                                )}
                              </Tooltip>

                              {showComponent("delete") && (
                                <Tooltip
                                  title="Delete Srf"
                                  placement="top-start"
                                >
                                  <Button
                                    id="srflist-deletesrf"
                                    onClick={(e) => {
                                      if (
                                        window.confirm(
                                          "Really want to delete srfs?"
                                        )
                                      ) {
                                        submitSiteDelete(row.id);
                                      }
                                    }}
                                  >
                                    <DeleteIcon style={{ color: "red" }} />
                                  </Button>
                                </Tooltip>
                              )}
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              id="srflist-pagination"
              rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              component="div"
              colSpan={3}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              ActionsComponent={TablePaginationActions}
            />
            <ToastContainer />
          </Box>
          {renderTimeline()}
          {renderPrintModal()}
          {errormodalIsOpen && (
            <ErrorModal
              errormodalIsOpen={errormodalIsOpen}
              closeErrorModal={closeErrorModal}
              errorMessage={errorMessage}
            />
          )}
        </Box>
      )}
    </>
  );
}
