import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  TextField,
  Autocomplete,
  useMediaQuery,
  Modal,
  Box,
  Grid,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../global";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { departmentListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No" },
  { id: "name", label: "Department Name", minWidth: 40 },
];

function createData(id, name, status) {
  return {
    id,
    name,
    status,
  };
}

export default function DepartmentList() {
  const departmentListGuides = departmentListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = React.useState(0);
  const [disciplines, setDiscList] = React.useState("");
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const _clientId = localStorage.getItem("clientId");
  const userType = localStorage.getItem("type");
  const [title, setTitle] = React.useState("");
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const [filterModalOpen, setFilterModalOpen] = React.useState(false);

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Department Name": "name",
        Status: "status",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` qt.date between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }
    if (userType != "1")
      whereConditions +=
        whereConditions.length > 0
          ? `and qt.clientId=${_clientId}`
          : `where qt.clientId=${_clientId}`;

    whereConditions +=
      whereConditions.length > 0 ? `and qt.status=1` : `where qt.status=1`;
    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `select * from departmentMaster as qt ${whereConditions} order by qt.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query =
        "select count(*) as count from departmentMaster where status = 1";

    return data;
  };

  const getDList = (event) => {
    let data = [getQuery(), getQuery(true)];
    axiosWithToken
      .post(BASE_URL + `dynamic`, data[0])
      .then((res) => {
        setDiscList(res.data);
      })
      .catch((err) => {
        console.log("departmentMaster data fetching error: ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getDList" ,"Department List")}><u>Error: {err.message}</u></h6>);
      });
    axiosWithToken
      .post(BASE_URL + `dynamic`, data[1])
      .then((res) => {
        setTotal(res.data[0].count);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getDList" ,"Department List")}><u>Error: {err.message}</u></h6>);
      });
  };

  useEffect(() => {
    getDList();
  }, []);

  const search = () => {
    getDList();
  };

  useEffect(() => {
    search();
  }, [total, rowsPerPage, page]);

  const submitSiteDelete = (id) => {
    console.log(id);

    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axiosWithToken
      .patch(url + `departmentMaster/${id}`, jsonData)
      .then((res) => {
        toast("Department was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "submitSiteDelete" ,"Department List")}><u>Error: {err.message}</u></h6>);
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };
  var rows = [];

  for (let i = 0; i < disciplines.length; i++) {
    rows.push(
      createData(disciplines[i].id, disciplines[i].name, disciplines[i].status)
    );
  }
  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 5px", marginBottom: "2px" }}
    >
      <Grid item xs={12} sm={5} md={2} lg={2} id="setting_list_searchby">
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          size="small"
          value={searchBy}
          id="department_list_searchby"
          options={[{ key: "name", label: "Department Name" }]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
          id="department_list_searchbytext"
          style={{ width: "100%" }}
          label={"enter " + searchBy}
          size="small"
          value={searchKey}
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} textAlign={"left"}>
        <Button
          id="department_list_searchbtn"
          variant="contained"
          size="small"
          onClick={() => {
            search();
            handleFilterClose();
          }}
        >
          <SearchIcon />
        </Button>
      </Grid>
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <Box sx={{ margin: "0px 5px", marginTop: "-0.8rem" }}>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar>
          <Button
            id="department_list_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/department/0"
            onClick={() => {
              setTitle("Create Department");
            }}
          >
            <b>Create Department</b>
          </Button>
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                departmentListGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </Box>
      </div>
      {!isMobile && filterSection()}
      <TableContainer sx={{ maxHeight: isMobile ? "80vh" : "65vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="department_list_table"
          sx={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Department" placement="top-start">
                        <Button
                          id="department_list_edit"
                          style={{
                            borderColor: "#dc3545",
                          }}
                          component={Link}
                          to={`/master/department/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Department" placement="top-start">
                        <Button
                          id="department_list_delete"
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Really want to delete Department?"
                              )
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
      
  {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </Box>
  );
}
