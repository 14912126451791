import React, { useState, useEffect } from "react";
import "./components/dashboard/style.css";
import { CircularProgress } from "@mui/material";

import Main from "./components/home/main";
import DeviceValidator from "./deviceValidator";
import logo from "./components/image/logo.png";


// Production configurations
if (process.env.NODE_ENV === 'production') {
  console.debug = () => {};
}

// Global helper functions
window.measurePerformance = function (func, ...args) {
  const start = Date.now();

  const result = func(...args);
  const end = Date.now();
  console.debug(`${String(func.name)}, Start time: ${start}, end time: ${end}, Duration: ${end - start} milliseconds`);

  return result;
};

function App() {
  const { upgradeDevice, deviceInfo } = DeviceValidator();
  const [loader, setLoader] = useState(undefined);
  useEffect(()=>{
    if(loader == false){
      window.location.reload(true);
    }
  }, [loader]);


  return (
    <div className="App">
      {loader && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            background: "rgba(255,255,255, 0.85)",
            zIndex: "2",
          }}
        >
          <CircularProgress style={{ width: "70px", height: "70px" }} />
        </div>
      )}
      {!upgradeDevice ? 
      <Main setLoader={setLoader} />
      : 
      <div className="limiter" style={{marginTop:"0px"}}>
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-pic js-tilt" data-tilt>
              <img className="" src={logo} alt="IMG" />
            </div>
            <div className="login100-form validate-form" style={{display: "flex",justifyContent: "center",
              alignItems: "center"}}> <h5>Please upgrade your plan to use this application in {deviceInfo}</h5></div>            
          </div>
        </div>
      </div>
      }
    </div>
  );
}
export default App;
