import * as React from "react";
import { BASE_URL, DISABLED_MODULE } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./user.css";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect } from "react";
import { useParams } from "react-router";
import { showComponent } from "../../helper/helpers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { useGetRoleInfo } from "../../login/Login";
import { createUserGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";


const moduleList = [
  {
    id: 1,
    name: "Dashboard",
  },
  {
    id: 2,
    name: "Master",
  },
  {
    id: 3,
    name: "SRF",
  },
  {
    id: 4,
    name: "Datasheets",
  },
  {
    id: 5,
    name: "Certificates",
  },
  {
    id: 7,
    name: "Courier Register",
  },
  {
    id: 8,
    name: "Expenses",
  },
  {
    id: 9,
    name: "Work Report",
  },
  {
    id: 10,
    name: "Summary Report",
  },
  {
    id: 11,
    name: "History Card",
  },
  {
    id: 12,
    name: "Scheduler",
  },
  {
    id: 14,
    name: "Commercial",
  },
  {
    id: 17,
    name: "Payment",
  },
  {
    id: 18,
    name: "Purchase",
  },
  {
    id: 21,
    name: "Master In/Out",
  },
  {
    id: 25,
    name: "User",
  },
  {
    id: 27,
    name: "Audit",
  },
  {
    id: 28,
    name: "Customer Complaints",
  },
  {
    id: 29,
    name: "Customer Feedback",
  },
  {
    id: 30,
    name: "Request Form",
  },
  {
    id: 31,
    name: "Document Upload",
  },
  {
    id: 32,
    name: "Help",
  },
  {
    id: 33,
    name: "Attendance",
  },
  {
    id: 34,
    name: "MarkAttendance",
  },
  ,
  {
    id: 35,
    name: "Remark",
  },
];

const EnabledModules = moduleList?.filter(
  (module) => !DISABLED_MODULE?.includes(module.name)
);

export const userDesignation = [
  {
    id: 1,
    name: "Calibration Engineer",
  },
  {
    id: 2,
    name: "Senior Engineer",
  },
  {
    id: 3,
    name: "Quality Manager",
  },
  {
    id: 4,
    name: "Technical Manager",
  },
  {
    id: 5,
    name: "Technical Head",
  },
  {
    id: 6,
    name: "Lab Head",
  },
  {
    id: 7,
    name: "Assistant Technical Manager",
  },
  {
    id: 8,
    name: "CEO",
  },
  {
    id: 9,
    name: "Junior Accountant",
  },
  {
    id: 10,
    name: "Senior Accountant",
  },
  {
    id: 11,
    name: "Account Head",
  },
];

export const userTypes = [
  {
    id: 1,
    label: "Admin",
  },
  {
    id: 4,
    label: "Branch Admin",
  },
  {
    id: 2,
    label: "Engineer",
  },
  {
    id: 3,
    label: "Client",
  },
  {
    id: 5,
    label: "Lab Co-Ordinator",
  },
  {
    id: 6,
    label: "Account Dept",
  },
];

const headers = [
  { name: "Client" },
  { name: "Address" },
  { name: "Start Date" },
  { name: "End Date" },
];

const rolesArray = [
  {
    name: "Calibration Authority ",
    id: "1",
  },
  {
    name: "Approved Authority",
    id: "2",
  },
  ,
  {
    name: "Reviewed By",
    id: "3",
  },
  ,
  {
    name: "Signed By",
    id: "4",
  },
  ,
  {
    name: "Witnessed By",
    id: "5",
  },
];

export default function EditUserMaster() {
  const createusersGuides = createUserGuide();
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [modules, setModules] = React.useState(null);
  const [type, setType] = React.useState("");
  const [designation, setDesignation] = React.useState([]);
  const [selectedModules, setSelectedModules] = React.useState(null);
  const [executing, setExecuting] = React.useState(false);
  const [passVal, setPasswordVal] = React.useState([0, 0, 0, 0, 0, 0]);
  const [companies, setCompanies] = React.useState(null);
  const [companiesD, setCompaniesD] = React.useState(null);
  const [clientArray, setClientArray] = React.useState(null);
  const [clientArrayD, setClientArrayD] = React.useState(null);
  const [userLoaded, setUserLoaded] = React.useState(false);
  const [branches, setBranches] = React.useState([]);
  const [selectedBranches, setSelectedBranch] = React.useState([]);
  // const [settingList, setSettingList] = React.useState({});
  const [roles, setRoles] = React.useState([]);
  const [uroles, setURoles] = React.useState([]);
  const [clientID, setclientID] = React.useState(null);
  const [filterRole, setFilterRole] = React.useState([]);
  // const rolesArray = useGetRoleInfo(true, true);
  const { id } = useParams();
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const filterClients = (clients, uBranches) => {
    if (!uBranches) return clients;
    return (clients || []).filter((c) => uBranches.includes(c.branch));
  };

  const getClientIdList = (uBranches) => {
    let url = BASE_URL;

    let Query = {
      query: `SELECT c.*, GROUP_CONCAT(a.address SEPARATOR ' || ') AS concatenated_addresses FROM clients AS c LEFT JOIN addresses AS a ON FIND_IN_SET(a.id, REPLACE(c.address, ',', ',')) > 0 WHERE c.status != -1 GROUP BY c.id`,
    };

    axiosWithToken
      .post(url + `dynamic`, Query)
      // .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(filterClients(res.data, uBranches));
        setClientArrayD(res.data);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getClientIdList" ,"EditUser Master")}><u>Error: {err.message}</u></h6>);
      });
  };

  const getBranches = (setBranch) => {
    axiosWithToken.get(BASE_URL + "branch?_where=(status,eq,1)").then((res) => {
      setBranch(res.data);
      setBranches(
        res.data.map((item) => {
          return {
            id: item.id,
            branchName: item.branchName,
          };
        })
      );
    }).catch((err)=>{
      toast.error(<h6 onClick={() => openErrorModal(err.message, "getBranches" ,"EditUser Master")}><u>Error: {err.message}</u></h6>);
    });
  };

  const onSubmit = async () => {
    setExecuting(true);
    // console.log("type : ", type);
    var jsonData = {
      userName: userName,
      password: password,
      type: type?.id,
      branch:
        type?.id !== 1
          ? (selectedBranches || []).map((b) => b.id).join(",")
          : null,
      modules:
        type?.id !== 1
          ? (selectedModules || []).map((m) => m.id).join(",")
          : null,
      // roles: roles.map((e) => e.id).join(","),
      roles: roles.join(","),
      clientId: clientID || null,
      designation: designation?.map((e) => e.id).join(","),
    };

    let ret = id
      ? axiosWithToken.patch(BASE_URL + `users/${id}`, jsonData)
      : axiosWithToken.post(BASE_URL + `users`, jsonData);
    ret
      .then((res) => {
        if (jsonData.type == 2 && companies) {
          let rows = [
            ...companies.map((company) => {
              return {
                userId: id ? id : res.data.insertId,
                companyId: company[0].id,
                startDate: company[1]
                  ? moment(company[1]).format("YYYY-MM-DD")
                  : null,
                endDate: company[2]
                  ? moment(company[2]).format("YYYY-MM-DD")
                  : null,
                status: 1,
                id: company[3],
              };
            }),
          ];
          let post = [],
            patch = [],
            patch_id = [],
            tmp = null;
          let tmp_id = null;
          rows.map((ua) => {
            tmp = { ...ua };
            delete tmp.id;
            if (ua.id) {
              patch_id.push(ua.id);
              patch.push(tmp);
            } else {
              post.push(tmp);
            }
          });
          let url = BASE_URL;
          Promise.all([
            ...post.map((pst) =>
              axiosWithToken.post(url + "userClientAssign", pst)
            ),
            ...patch.map((ptch, i) =>
              axiosWithToken.patch(
                url + `userClientAssign/${patch_id[i]}`,
                ptch
              )
            ),
          ])
            .then((res2) => {
              toast.success("Updated Sucessfully!");
              refresh();
            })
            .catch((err) => {
              toast.error(<h6 onClick={() => openErrorModal(err.message, "onSubmit" ,"EditUser Master")}><u>Error: {err.message}</u></h6>);
              // console.log("Companies error   : ", err);
              setExecuting(false);
            });
        } else {
          toast.success("Updated Sucessfully!");
          refresh();
        }
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "onSubmit" ,"EditUser Master")}><u>Error: {err.message}</u></h6>);
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const initModules = () => {
    setModules(EnabledModules);
  };

  function fetchUserDetails() {
    axiosWithToken.get(BASE_URL + `users/${id}`).then((res) => {
      setUserName(res.data[0].userName);
      setPassword(res.data[0].password);
      setclientID(res.data[0]?.clientId);
      if (res.data[0].password) {
        let vals = passVal;

        let lowerCaseLetters = /[a-z]/g;
        res.data[0].password.match(lowerCaseLetters)
          ? (vals[0] = 1)
          : (vals[0] = 0);

        // Validate capital letters
        let upperCaseLetters = /[A-Z]/g;
        res.data[0].password.match(upperCaseLetters)
          ? (vals[1] = 1)
          : (vals[1] = 0);

        // Validate numbers
        let numbers = /[0-9]/g;
        res.data[0].password.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

        // Validate length
        res.data[0].password.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
        res.data[0].password.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

        setPasswordVal(vals);
      }
      setType(userTypes.filter((t) => t.id == res.data[0].type)[0]);
      res.data[0].modules
        ? setSelectedModules(
            res.data[0].modules
              ?.split(",")
              .map(
                (groupId) =>
                  EnabledModules.find(
                    (group) => group.id === parseInt(groupId, 10)
                  ) || null
              )
              .filter((group) => group !== null)
          )
        : setSelectedModules([]);

      res.data[0]?.designation
        ? setDesignation(
            res.data[0]?.designation
              ?.split(",")
              .map((m) => userDesignation[m - 1] || [])
          )
        : setDesignation([]);

      // Split the roles string into an array of role IDs
      const roleIds = res?.data[0]?.roles?.split(",");
      setRoles(roleIds);
      getBranches((inpt) => {
        let tmp = {};
        inpt.map((e) => (tmp[e.id] = e));
        res.data[0].branch
          ? setSelectedBranch(
              res.data[0].branch?.split(",").map((b) => tmp[b]) || []
            )
          : setSelectedBranch([]);
        getClientIdList((res.data[0].branch || "").split(","));
      });
      axiosWithToken
        .get(BASE_URL + `userClientAssign?_where=(userId,eq,${id})})`)
        .then((res2) => {
          // console.log("client company mapping : ", res2.data);
          let tCompanies = [];
          setCompaniesD(res2.data);
          setUserLoaded(true);
        });
    });
  }

  useEffect(() => {
    initModules();
    if (id) fetchUserDetails();
    if (!id) getBranches(() => {});
    if (!id) getClientIdList();
  }, []);

  useEffect(() => {
    if (roles?.length > 0) {
      let roleArray = [];
      roles?.forEach((roleId) => {
        let roleObject = filterRole?.find((ele) => ele?.id == roleId);
        if (roleObject) {
          roleArray.push(roleObject);
        }
      });
      setURoles(roleArray);
    }
  }, [roles, filterRole, id]);

  useEffect(() => {
    let filterRole;
    if (type?.id == 2) {
      filterRole = rolesArray.filter((role) => role.id == 1 || role.id == 2);
    } else if (type?.id == 3) {
      filterRole = rolesArray.filter(
        (role) => role.id == 3 || role.id == 4 || role.id == 5
      );
    } else {
      filterRole = rolesArray;
    }
    setFilterRole(filterRole);
  }, [type?.id]);

  useEffect(() => {
    if ((id ? userLoaded : true) && companiesD && clientArray) {
      let validCompanies = companiesD?.map((c1) => [
        clientArray?.filter((c) => c?.id == c1?.companyId)[0] || null,
        c1.startDate,
        c1.endDate,
        c1.id,
      ]);
      setCompanies([...validCompanies]);
    }
  }, [companiesD, userLoaded, clientArray]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          {id
            ? `Edit ${type?.label ? type?.label : ""} : ${userName}`
            : "Add User"}
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              createusersGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="create_users_username"
            label="Username *"
            value={userName}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="create_users_password"
            label="Password *"
            value={password}
            size="small"
            type={id ? "password" : "text"}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              let vals = passVal;

              let lowerCaseLetters = /[a-z]/g;
              e.target.value.match(lowerCaseLetters)
                ? (vals[0] = 1)
                : (vals[0] = 0);

              // Validate capital letters
              let upperCaseLetters = /[A-Z]/g;
              e.target.value.match(upperCaseLetters)
                ? (vals[1] = 1)
                : (vals[1] = 0);

              // Validate numbers
              let numbers = /[0-9]/g;
              e.target.value.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

              // Validate length
              e.target.value.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
              e.target.value.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

              setPasswordVal(vals);
              setPassword(e.target.value);
            }}
          />
          <div>
            <p id="letter" class={passVal[0] ? "valid" : "invalid"}>
              A <b>lowercase</b> letter
            </p>
            <p id="capital" class={passVal[1] ? "valid" : "invalid"}>
              A <b>capital (uppercase)</b> letter
            </p>
            <p id="number" class={passVal[2] ? "valid" : "invalid"}>
              A <b>number</b>
            </p>
            <p id="lengthMin" class={passVal[3] ? "valid" : "invalid"}>
              Minimum <b>8 characters</b>
            </p>
            <p id="lengthMax" class={passVal[4] ? "valid" : "invalid"}>
              Maximum <b>32 characters</b>
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {!id && (
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="create_users_usertype"
              options={userTypes}
              value={type}
              renderInput={(params) => (
                <TextField {...params} label="User Type *" />
              )}
              onChange={(e, val) => {
                setType(val);
              }}
            />
          </Grid>
        )}
        {type?.id == 3 && (
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              options={clientArrayD}
              value={
                clientArrayD?.filter((ele) => ele?.id == clientID)?.[0] || null
              }
              getOptionLabel={(option) => `${option.id}, ${option.companyName}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a client"
                  variant="outlined"
                />
              )}
              onChange={(event, value) => {
                setclientID(value?.id);
              }}
            />
          </Grid>
        )}
        {type?.id != 5 && type?.id != 6 && (
          <Grid item xs={3} id="create_users_roles">
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={filterRole}
              defaultValue={undefined}
              value={uroles || null}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              multiple
              renderInput={(params) => <TextField {...params} label="Roles" />}
              onChange={(event, value) => {
                // console.log("value",value)
                // setRoles(value);
                setRoles([...(value?.map((e) => e.id) || [])]);
              }}
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <Autocomplete
            multiple
            id="action"
            size="small"
            options={
              type && type.id == 6
                ? userDesignation.filter((d) => [9, 10, 11].includes(d.id))
                : userDesignation || []
            }
            defaultValue={undefined}
            value={designation || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />

                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User Designation"
                placeholder="select user designation"
              />
            )}
            onChange={(event, value) => setDesignation(value || [])}
          />
        </Grid>

        {type?.id !== 1 && (
          <Grid item xs={3} id="create_users_branch">
            <Autocomplete
              multiple
              fullWidth
              id="branch"
              size="small"
              options={branches || []}
              defaultValue={undefined}
              value={selectedBranches || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.branchName || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.branchName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Branch"
                  placeholder="Select Branch"
                />
              )}
              onChange={(event, value) => {
                setClientArray(
                  filterClients(
                    clientArrayD,
                    value.map((b) => `${b.id}`)
                  )
                );
                setSelectedBranch(value || []);
              }}
            />
          </Grid>
        )}
        {type?.id !== 1 && (
          <Grid
            item
            xs={id && type?.id === 2 ? 6 : 3}
            id="create_users_modules"
          >
            <Autocomplete
              multiple
              fullWidth
              id="modules"
              size="small"
              options={modules || []}
              defaultValue={undefined}
              value={selectedModules || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />

                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select modules (optional)"
                  placeholder="select multiple modules"
                />
              )}
              onChange={(event, value) => setSelectedModules(value || [])}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: "16px" }}>
        {type?.id === 2 && (
          <Grid item xs={9}>
            <Box sx={{ width: "100%" }} id="create_users_branchapply">
              <table className="user-client-table">
                <tr>
                  {headers.map((header, id) => (
                    <th key={`header-${id}`}>{header.name}</th>
                  ))}
                  {(companies || []).length > 1 && showComponent("delete") ? (
                    <th></th>
                  ) : (
                    ""
                  )}
                </tr>
                {(companies || []).map((e, idx) => {
                  const concatenatedAddresses =
                    e[0]?.concatenated_addresses || "";
                  const addressesArray = concatenatedAddresses.split(" || ");
                  return (
                    <tr key={idx}>
                      <td>
                        <Autocomplete
                          fullWidth
                          id="checkboxes-tags-demo"
                          size="small"
                          value={e[0] ? e[0] : {}}
                          options={clientArray || []}
                          // disableCloseOnSelect
                          getOptionLabel={(option) => option.companyName || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Client"
                              // variant="standard"
                            />
                          )}
                          onChange={(event, value) => {
                            let tmp = [...companies];
                            tmp[idx][0] = { ...value };
                            setCompanies([...tmp]);
                          }}
                          // slotProps={{ textField: { variant: "standard" } }}
                        />
                      </td>
                      {/* <td>{e[0]?.concatenated_addresses || ""}</td> */}

                      <td>
                        {addressesArray.length === 1 ? (
                          addressesArray[0]
                        ) : (
                          <Autocomplete
                            fullWidth
                            id="checkboxes-tags-demo"
                            size="small"
                            options={addressesArray}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Addresses"
                                variant="outlined"
                              />
                            )}
                            // Additional Autocomplete props
                            // ...
                          />
                        )}
                      </td>

                      <td>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            format="dd/MM/yyyy"
                            value={e[1] ? new Date(e[1]) : ""}
                            onChange={(newValue) => {
                              let tmp = [...companies];
                              tmp[idx][1] = newValue;
                              setCompanies([...tmp]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" fullWidth />
                            )}
                            // slotProps={{ textField: { variant: "standard" } }}
                          />
                        </LocalizationProvider>
                      </td>
                      <td
                        style={
                          !e[2] || new Date(e[2]) < new Date()
                            ? { backgroundColor: "#faa" }
                            : {}
                        }
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            format="dd/MM/yyyy"
                            value={e[2] ? new Date(e[2]) : ""}
                            onChange={(newValue) => {
                              let tmp = [...companies];
                              tmp[idx][2] = newValue;
                              setCompanies([...tmp]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" fullWidth />
                            )}
                            // slotProps={{ textField: { variant: "standard" } }}
                          />
                        </LocalizationProvider>
                      </td>
                      {(companies || []).length > 1 &&
                      showComponent("delete") ? (
                        <td width={"15px"}>
                          <DeleteIcon
                            style={{ color: "#dc3545" }}
                            onClick={(e) => {
                              let tmp = (companies || []).filter(
                                (_, i) => i !== idx
                              );
                              axiosWithToken
                                .delete(
                                  BASE_URL +
                                    `userClientAssign/${companies[idx][3]}`
                                )
                                .then((res) =>
                                  toast.success("deleted successfully!")
                                )
                                .catch((err) => {
                                  console.log("err : ", err);
                                  toast.error(<h6 onClick={() => openErrorModal(err.message, "DeleteIcon" ,"EditUser Master")}><u>Error: {err.message}</u></h6>);
                                });
                              setCompanies(tmp);
                            }}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
                <tr>
                  <td
                    colSpan={
                      (companies || []).length > 1
                        ? headers.length + 1
                        : headers.length
                    }
                  >
                    <Button
                      onClick={(e) => {
                        setCompanies([...(companies || []), [[], "", ""]]);
                      }}
                    >
                      <b style={{ fontSize: "18px" }}>ADD CLIENT</b>
                      <AddIcon />
                    </Button>
                  </td>
                </tr>
              </table>
            </Box>
          </Grid>
        )}
      </Grid>
      <br />

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          id="create_users_savebtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={
            executing ||
            userName === "" ||
            password === "" ||
            passVal.reduce((s, v) => s + v, 0) < 5 ||
            (!id && !type?.label)
          }
          onClick={() => {
            onSubmit();
          }}
        >
          {`${id ? "Update" : "Create"} ${
            type && type?.label ? `${type?.label} : ` : ""
          } ${userName}`}
        </Button>
      </Toolbar>
      {/* <ToastContainer /> */}
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}

    </Paper>
  );
}
