// Use This URL for testing
const URL = "navsharcal.bfiinfotech.co.in";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "State Bank OF India , Navi Mumbai",
  "Bank A/c. No.": "39813124209",
  "IFSC Code": "SBIN0061520",
};
export const GST_NUMBER = "27DSVPK5381Q1ZH";

export const DISABLED_MODULE =["Summary Report","Courier Register","History Card","Expenses","Master In/Out","Customer Complaints","Customer Feedback","Audit","Document Upload","User","Purchase"
	,"Commercial","Payment","DUC Master","Department","Request Form","attendance","MarkAttendance","CoordinatesAddress"]; 


export const DEVICE_TYPE = ["desktop"];
