import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Tooltip from "@mui/material/Tooltip";
import { Autocomplete, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { showComponent } from "../helper/helpers";
import "../helper/helper.css";
import ExcelDownload from "../../utils/components/excelDownload";
import PrintIcon from "@mui/icons-material/Print";
import { invoicenListGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";

const _clientId = localStorage.getItem("clientId");
const userType = localStorage.getItem("type");
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "clientName", label: "Client", minWidth: 40 },
  { id: "address", label: "Address", minWidth: 40 },
  { id: "invoiceNumber", label: "Invoice Number", minWidth: 40 },
  {
    id: "invoiceDate",
    label: "Invoice Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "poNumber",
    label: "PO Number",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },

  {
    id: "createdBy",
    label: "Created By",
    align: "left",
    minWidth: 40,
  },
];

function createData(
  id,
  clientName,
  address,
  invoiceNumber,
  invoiceDate,
  clientId,
  poNumber,
  createdBy
) {
  return {
    id,
    clientName,
    address,
    invoiceNumber,
    invoiceDate,
    clientId,
    poNumber,
    createdBy,
  };
}
const notNeededColumn = ["id", "clientId", "createdBy"];

export default function InvoiceList(props) {
  const invoicenListGuides = invoicenListGuide();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [invoiceList, setInvoiceList] = React.useState([]);
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (rowIndex, id) => {
    props.setLoader(true);
    let newInvoiceData = [...invoiceData];

    if (newInvoiceData[rowIndex] != undefined) {
      axiosWithToken
        .delete(BASE_URL + `customInvoice/${id}`)
        .then((res) => {
          toast.success("deleted successfully");
          props.setLoader(false);
          newInvoiceData.splice(rowIndex, 1);
          setInvoiceData([...newInvoiceData]);
        })
        .catch((err) => {
          console.log("err : ", err);
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "submitSiteDelete", "Invoice List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
          props.setLoader(false);
        });
    }
  };

  //customInvoice

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        Client: "client.companyName",
        "PO Number": "invoice.poNumber",
        "Invoice Number": "invoice.invoiceNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` invoice.invoiceDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }
    if (userType != "1")
      whereConditions +=
        whereConditions.length > 0
          ? `and invoice.clientId=${_clientId}`
          : `where invoice.clientId=${_clientId}`;
    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `SELECT client.companyName, invoice.clientId, invoice.poNumber, invoice.invoiceNumber, invoice.invoiceDate, invoice.poDate, invoice.createdBy, invoice.id, a.address AS address FROM customInvoice AS invoice JOIN clients AS client ON invoice.clientId = client.id LEFT JOIN addresses AS a ON a.id = SUBSTRING_INDEX(SUBSTRING_INDEX(invoice.address, ',', 1), ',', -1) ${whereConditions} ORDER BY invoice.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(invoice.id) as no_of_rows FROM customInvoice AS invoice JOIN clients AS client ON invoice.clientId = client.id LEFT JOIN addresses AS a ON a.id = SUBSTRING_INDEX(SUBSTRING_INDEX(invoice.address, ',', 1), ',', -1) ${whereConditions} ORDER BY invoice.id DESC ${pagination_settings}`;
    return data;
  };

  function getAllInvoice() {
    const data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setInvoiceList(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "getAllInvoice", "Invoice List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
        props.setLoader(false);
      });
  }

  function getTotalRows() {
    const data = getQuery("count");
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "getTotalRows", "Invoice List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      });
  }

  const initiatInvoiceList = () => {
    var rowData = [];

    for (let i = 0; i < invoiceList.length; i++) {
      rowData.push(
        createData(
          invoiceList[i].id,
          invoiceList[i].companyName,
          invoiceList[i].address,
          invoiceList[i].invoiceNumber,
          invoiceList[i].invoiceDate
            ? moment(invoiceList[i].invoiceDate).format("DD-MM-YYYY")
            : "",
          invoiceList[i].clientId,
          invoiceList[i].poNumber,

          invoiceList[i].createdBy
        )
      );
    }

    setInvoiceData(rowData);
  };

  // api calls
  function getUsers() {
    const data = {
      query: "SELECT * FROM users",
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "getUsers", "Invoice List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      });
  }

  const search = () => {
    getTotalRows();
    getAllInvoice();
  };

  useEffect(() => {
    initiatInvoiceList();
  }, [invoiceList]);

  useEffect(() => {
    getAllInvoice();
  }, [rowsPerPage, page]);

  useEffect(() => {
    getUsers();
    getTotalRows();
  }, []);

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar>
          {showComponent("add") && (
            <Button
              id="invoicelist_createbtn"
              variant="contained"
              size="small"
              component={Link}
              to="/Invoice/createInvoice"
            >
              CREATE INVOICE
            </Button>
          )}
        </Toolbar>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              invoicenListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <hr />

      <div
        className="mb-2"
        style={{ display: "flex", float: "right", justifyContent: "flex-end" }}
      >
        <div
          style={{ marginLeft: "10px", marginRight: "10px" }}
          id="invoicelist_datefrom"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }} id="invoicelist_dateto">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="invoicelist_searchby"
          options={[
            { key: "companyName", label: "Client" },
            { key: "poNumber", label: "PO Number" },
            { key: "invoiceNumber", label: "Invoice Number" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="invoicelist_searchbytext"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
          sx={{ marginLeft: "10px" }}
        />
        <Button
          id="invoicelist_searchbtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
        <ExcelDownload
          finalData={invoiceData}
          notNeededColumn={notNeededColumn}
        />
      </div>
      <br />

      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="invoicelist_table"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceData
            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    // let value = row[column.id];
                    let value;
                    if (column.id === "createdBy") {
                      let userObject = users.filter(
                        (e) => e.id === row[column.id]
                      );
                      value = row[column.id] ? userObject[0]?.userName : null;
                    } else {
                      value = row[column.id];
                    }
                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup
                      size="small"
                      aria-label="small button group"
                      className="action-container"
                    >
                      {showComponent("show") && (
                        <Tooltip title="Edit Invoice" placement="top-start">
                          <Button
                            id="invoicelist_edit"
                            component={Link}
                            to={`/Invoice/editInvoice/${row.clientId}/${row.poNumber}/${row.id}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                      )}

                      <Tooltip title="Print Invoice" placement="top-start">
                        <Button
                          id="invoicelist_download"
                          component={Link}
                          to={`/Invoice/viewInvoice/${row.clientId}/${row.id}`}
                        >
                          <PrintIcon />
                        </Button>
                      </Tooltip>
                      {showComponent("delete") && (
                        <Tooltip title="Delete Invoice" placement="top-start">
                          <Button
                            id="invoicelist_delete"
                            onClick={(e) => {
                              if (
                                window.confirm("Really want to delete Invoice?")
                              ) {
                                submitSiteDelete(
                                  page * rowsPerPage + index,
                                  row.id
                                );
                              }
                            }}
                          >
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </TableContainer>
  );
}
