import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  useMediaQuery,
} from "@mui/material";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import HelpIcon from "@mui/icons-material/Help";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { BASE_URL } from "./../../global";
import { Grid, TextField, Toolbar } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { showComponent } from "../helper/helpers";
import { dataSheetListGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

export default function DatasheetList() {
  const [page, setPage] = React.useState(0);
  const DataSheetListGuides = dataSheetListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [datasheet, setDatasheet] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [enableDisable, setEnableData] = React.useState("");
  const [usersArray, setUsersArray] = React.useState([]);
  const [allUsers, setUsers] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [userId, setUserId] = React.useState(localStorage.getItem("id"));
  const [branch, setBranch] = React.useState(localStorage.getItem("branch"));
  const [result, setResult] = React.useState([]);
  const showJobAssign = userType !== "2" ? true : false;
  const _clientId = localStorage.getItem("clientId");
  const validCompanies = localStorage.getItem("validClients");
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const columns = (() =>
    userType !== "2"
      ? [
          {
            id: "checkMark",
            label: "Select",
            align: "center",
            minWidth: 10,
          },
        ]
      : [])().concat([
    {
      id: "id",
      label: "Sr. No.",
      align: "left",
      minWidth: 40,
    },
    {
      id: "customerName",
      label: "Client",
      align: "left",
      minWidth: 40,
    },
    {
      id: "serviceReqNumber",
      label: "service Request Number",
      align: "left",
      minWidth: 40,
    },
    {
      id: "jobNumber",
      label: "Job Number",
      align: "left",
      minWidth: 40,
    },
    {
      id: "instrumentName",
      label: "Instrument Name",
      align: "left",
      minWidth: 40,
    },
    {
      id: "range",
      label: "Range",
      align: "left",
      minWidth: 40,
    },
    {
      id: "lc",
      label: "LC",
      align: "left",
      minWidth: 40,
    },
    {
      id: "DUCID",
      label: "DUC ID",
      align: "left",
      minWidth: 40,
    },
    {
      id: "serialNumber",
      label: "Serial Number",
      align: "left",
      minWidth: 40,
    },
    {
      id: "dateOfCalibration",
      label: "Date of Calibration",
      minWidth: 40,
      align: "left",
    },
    {
      id: "nextDueDate",
      label: "Due Date",
      minWidth: 40,
      align: "left",
    },
    {
      id: "assignStatus",
      label: "Assign status",
      minWidth: 40,
      align: "left",
    },
  ]);

  const updateEnableDisable = (value, id) => {
    const newColumnName = [...enableDisable];
    newColumnName[id] = id + "," + value;
    setEnableData(newColumnName);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const processEnableDisable = (enableDisable) => {
    if (enableDisable.length > 0) {
      const newResult = enableDisable
        .map(function (item) {
          if (item) {
            let [id, closed] = item.split(",");
            return closed === "true" ? id : null;
          }
        })
        .filter(function (item) {
          return item !== undefined && item !== null;
        });

      return newResult;
    }

    return [];
  };

  const getQuery = (count) => {
    let whereConditions = "where ds.status = 1 ";
    let pagination_settings = "";
    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Inst. Name": "ins.instrumentName",
        "Cust. Name": "cust.companyName",
        Range: "ds.ranges",
        "DUC ID": "ds.DUCID",
        "Serial Number": "ds.serialNumber",
        "SRF Number": "ds.serviceReqNumber",
        "Job Number": "ds.jobNumber",
      }[searchBy];
      whereConditions += `and ${colName} like '%${searchKey}%' `;
    }

    if (to != null && from != null) {
      whereConditions += ` and ds.calibrationDate between '${moment(
        from
      ).format("YYYY-MM-DD")}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (userType == "2") {
      whereConditions +=
        whereConditions.length > 0
          ? `and (ds.assignedUser=${userId}`
          : `where (ds.assignedUser=${userId}`;
      whereConditions += ` or ds.createdby=${userId}`;
      whereConditions +=
        validCompanies?.length > 0
          ? whereConditions.length > 0
            ? ` or cust.id in (${validCompanies}))`
            : ` where cust.id in (${validCompanies})`
          : ")";
    }

    if (userType == "3") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (ds.clientId=${_clientId})`
          : `where (ds.clientId=${_clientId})`;
    }

    whereConditions +=
      userType == 4 && branch && branch.length
        ? (whereConditions ? " and " : "") + ` cust.branch in (${branch})`
        : "";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `select ds.clientId,cust.companyName, ins.instrumentName,ins.id as insId,ds.id,ds.workOrderNumber,ds.certificateNumber,ds.calibrationDate,ds.calProcRefNo,ds.ranges,ds.lc,ds.DUCID,ds.identificationNo,ds.jobNumber,ds.nextDueDate,ds.dateOfIssue,ds.assignedUser,ds.serialNumber,ds.serviceReqNumber from datasheets as ds left join instruments as ins on ds.instrumentId = ins.id left join clients as cust on ds.clientId = cust.id  ${whereConditions} order by ds.id DESC ${pagination_settings}`,
    };
    if (count)
      data.query = `select count(*) as no_of_rows from datasheets as ds left join instruments as ins on ds.instrumentId = ins.id left join clients as cust on ds.clientId = cust.id  ${whereConditions} order by ds.id DESC ${pagination_settings}`;

    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.error("datasheet data fetching error: ", err);
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "getTotalRows", "Datasheet List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      });
  }

  function fetchDatasheet() {
    let data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setDatasheet([...res.data]);
        setFinalData([...res.data]);
      })
      .catch((err) => {
        console.error("datasheet data fetching error: ", err);
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "fetchDatasheet", "Datasheet List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      });
  }

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getUserList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "users?_where=(type,eq,2)")
      .then((res) => {
        setUsersArray(res.data);
      })
      .catch((err) => {
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, "getUserList", "Datasheet List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      });
  };
  const initializeuserData = () => {
    var pushCArray = [];
    for (let i = 0; i < usersArray.length; i++) {
      pushCArray.push({
        label: usersArray[i].id + ", " + usersArray[i].userName,
      });
    }
    setUsers(pushCArray);
  };

  const saveUserAndDsID = (userId, dsId) => {
    let url = BASE_URL;

    var data = {};
    var dsIdArr = dsId.split(",");
    if (userId) {
      dsIdArr.forEach((element) => {
        let data = {
          query: `UPDATE datasheets SET assignedUser = ${userId} WHERE id=${element};`,
        };
        axiosWithToken
          .post(BASE_URL + `dynamic`, data)
          .then((res) => {
            toast("Datasheet Assigned.");
          })
          .catch((err) => {
            toast.error(
              <h6
                onClick={() =>
                  openErrorModal(
                    err.message,
                    "saveUserAndDsID",
                    "Datasheet List"
                  )
                }
              >
                <u>Error: {err.message}</u>
              </h6>
            );
          });
      });
    }
  };

  const search = () => {
    fetchDatasheet();
  };

  useEffect(() => {
    setResult(processEnableDisable(enableDisable));
  }, [enableDisable]);

  useEffect(() => {
    getTotalRows();
    getUserList();
  }, []);

  useEffect(() => {
    initializeuserData();
  }, [usersArray]);

  useEffect(() => {
    fetchDatasheet();
  }, [rowsPerPage, page]);

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const filterSection = () => (
    <div>
      <Grid
        container
        spacing={2}
        // justifyContent="flex-end"
        alignItems="center"
        style={{ padding: "10px 5px" }}
      >
        {showJobAssign && result && result.length ? (
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
              }}
              size="small"
              id="combo-box-demo"
              options={allUsers}
              renderInput={(params) => (
                <TextField {...params} label="Job Assign" />
              )}
              onInputChange={(event, newInputValue) => {
                saveUserAndDsID(
                  parseInt(newInputValue.split(",")[0]),
                  result ? result.toString() : ""
                );
              }}
            />
          </Grid>
        ) : null}
        <Grid item xs={6} sm={6} md={2} lg={2} id="datasheet_list_datefrom">
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} id="datasheet_list_dateto">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
            }}
            size="small"
            value={searchBy}
            id="datasheet_list_searchby"
            options={[
              { key: "customerName", label: "Cust. Name" },
              { key: "instrumentName", label: "Inst. Name" },
              { key: "range", label: "Range" },
              { key: "duc", label: "DUC ID" },
              { key: "serialNumber", label: "Serial Number" },
              { key: "srfNumber", label: "SRF Number" },
              { key: "jobNumber", label: "Job Number" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              if (newInputValue === "") setSearchKey("");
              setSearchBy(newInputValue);
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          id="datasheet_list_searchbytext"
        >
          <TextField
            style={{ width: "100%" }}
            label={"Enter " + searchBy}
            size="small"
            variant="outlined"
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          textAlign={"left"}
          id="datasheet_list_searchbtn"
        >
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              search();
              handleFilterClose();
            }}
          >
            <SearchIcon />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <Box sx={{ margin: "0px 5px" }}>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          style={{ display: isMobile ? "block" : "none" }}
          onClick={handleFilterOpen}
        >
          <FilterAltIcon />
        </Button>
      </div>
      <div
        style={{
          position: "fixed",
          top: isMobile ? "6.6%" : "10%",
          right: isMobile ? "-1%" : "0%",
          zIndex: 99,
        }}
      >
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              DataSheetListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      {!isMobile && filterSection()}
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: isMobile ? "85vh" : "75vh" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            class="table-responsive"
            size="small"
            id="datasheet_list_table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
                <TableCell>
                  <b>Edit</b>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {finalData &&
                finalData.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {userType != "2" ? (
                        <TableCell>
                          <Grid item xs={2}>
                            <FormControlLabel
                              classes={{
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              sx={{
                                gap: 2,
                              }}
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    updateEnableDisable(
                                      e.target.checked,
                                      row.id
                                    );
                                  }}
                                />
                              }
                            />
                          </Grid>
                        </TableCell>
                      ) : null}
                      <TableCell>
                        {rowsPerPage * Number(page) + index + 1}
                      </TableCell>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>{row.serviceReqNumber}</TableCell>
                      <TableCell>{row.jobNumber}</TableCell>
                      <TableCell>{row.instrumentName}</TableCell>
                      <TableCell>
                        {row.ranges
                          ?.replaceAll("||", ", ")
                          ?.replaceAll("|", " to ")
                          ?.replaceAll("#", "")}
                      </TableCell>
                      <TableCell>
                        {row.lc
                          ?.replaceAll("||", ", ")
                          ?.replaceAll("|", " to ")
                          ?.replaceAll("#", "")}
                      </TableCell>
                      <TableCell>{row.DUCID}</TableCell>
                      <TableCell>{row.serialNumber}</TableCell>
                      <TableCell>
                        {row.calibrationDate
                          ? moment(row.calibrationDate).format("DD-MM-YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row.nextDueDate
                          ? moment(row.nextDueDate).format("DD-MM-YYYY")
                          : ""}
                      </TableCell>
                      <TableCell style={{ color: "green" }}>
                        {row.assignedUser != null ? "Assigned" : ""}
                      </TableCell>
                      <TableCell>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                        >
                          <Tooltip title="Edit Datasheet" placement="top-start">
                            <Button
                              id="datasheet_list_editbutton"
                              component={Link}
                              to={`/datasheet/edit/${row.id}/${row.insId}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Print Datasheet"
                            placement="top-start"
                          >
                            <Button
                              id="datasheet_list_print"
                              component={Link}
                              to={`/datasheet/edit/${row.id}/${row.insId}/print`}
                            >
                              <PrintIcon />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
        {errormodalIsOpen && (
          <ErrorModal
            errormodalIsOpen={errormodalIsOpen}
            closeErrorModal={closeErrorModal}
            errorMessage={errorMessage}
          />
        )}
      </Box>
    </Box>
  );
}
