import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import moment from "moment";
import { Grid, TextField, Autocomplete, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";
import { BASE_URL } from "./../../global";
import ExcelDownload from "../../utils/components/excelDownload";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "ulrNo", label: "ULR No.", minWidth: 40 },
  // { id: "companyName", label: "Client", minWidth: 40 },
  { id: "certificateNumber", label: "Certificate No.", minWidth: 40 },
  {
    id: "InstrumentId",
    label: "Instrument Name",
    minWidth: 40,
    align: "left",
  },
  {
    id: "ranges",
    label: "Range",
    minWidth: 40,
    align: "left",
  },
  {
    id: "lc",
    label: "LC",
    minWidth: 40,
    align: "left",
  },
  {
    id: "accuracy",
    label: "Accuracy",
    minWidth: 40,
    align: "left",
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    minWidth: 40,
    align: "left",
  },
  {
    id: "ducId",
    label: "Duc Id",
    minWidth: 40,
    align: "left",
  },
  {
    id: "make",
    label: "Make",
    minWidth: 40,
    align: "left",
  },
  {
    id: "model",
    label: "Model",
    minWidth: 40,
    align: "left",
  },

  {
    id: "calibrationDate",
    label: "Calibration date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "dueDate",
    label: "Due date",
    minWidth: 40,
    align: "left",
  },
];

function createData(
  id,
  ulrNo,
  companyName,
  certificateNumber,
  InstrumentId,
  ranges,
  lc,
  accuracy,
  serialNumber,
  ducId,
  make,
  model,
  calibrationDate,
  dueDate
) {
  return {
    id,
    ulrNo,
    companyName,
    certificateNumber,
    InstrumentId,
    ranges,
    lc,
    accuracy,
    serialNumber,
    ducId,
    make,
    model,
    calibrationDate,
    dueDate,
  };
}
let notNeededColumn = ["id"];
export default function SummaryReport() {
  const [page, setPage] = React.useState(0);
  const [customer, setCustomer] = React.useState(0);
  const [fromDate, setFromDate] = React.useState("");
  const [data, setData] = React.useState("");
  const [company, setCompany] = React.useState([]);
  const [toDate, setToDate] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [dateData, setDateData] = React.useState([]);
  const [calData, setCalData] = React.useState([]);
  const [ids, setIds] = React.useState([]);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");

  const FilterData = () => {
    let resultProductData;

    if (!company || company.length === 0) {
      // No specific companies selected, filter only by date range
      resultProductData = data?.filter(
        (data) =>
          new Date(data.ds_calibrationDate) >= new Date(fromDate) &&
          new Date(data.ds_calibrationDate) <= new Date(toDate)
      );
    } else {
      // Filter by date range and selected companies
      resultProductData = data?.filter(
        (datas) =>
          new Date(datas.ds_calibrationDate) >= new Date(fromDate) &&
          new Date(datas.ds_calibrationDate) <= new Date(toDate) &&
          company.includes(datas.client_id)
      );
    }

    // Update the state with filtered data
    setDateData(resultProductData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    let endPoint = "";
    endPoint =
      _userType == "3"
        ? `clients/${_clientId}?_where=(status,eq,1)`
        : `clients?_where=(status,eq,1)`;
    axiosWithToken
      .get(url + endPoint)
      .then((res) => {
        setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var pushArray = [];

  for (let i = 0; i < customer.length; i++) {
    pushArray.push({
      id: customer[i].id,
      label: customer[i].companyName,
    });
  }

  const getData = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          "xjoin?_join=ds.datasheets,_j,client.clients,_j,inst.instruments,_j,cert.certificates&_on1=(ds.clientId,eq,client.id)&_on2=(ds.instrumentId,eq,inst.id)&_on3=(cert.id,eq,ds.id)&_fields=client.companyName,client.contact,client.address,inst.instrumentName,cert.ULRNo,cert.certificateNumber,ds.calibrationDate,ds.nextDueDate,client.id,ds.id,ds.lc,ds.DUCID,ds.make,ds.model,ds.ranges,ds.accuracy,ds.serialNumber"
      )
      .then((res) => {
        setData(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const initializeRowTable = () => {
    var rowData = [];
    var idAr = [];
    for (let i = 0; i < dateData.length; i++) {
      idAr.push(dateData[i].ds_id);
      rowData.push(
        createData(
          dateData[i].ds_id,
          dateData[i].cert_ULRNo,
          dateData[i].client_companyName,
          dateData[i].cert_certificateNumber,
          dateData[i].inst_instrumentName,
          dateData[i].ds_ranges.split("||").map((range) => {
            let tmp = range.split("|");
            tmp[0] = tmp[0].split("#");
            if (tmp[1]) {
              tmp[1] = tmp[1].split("#");
            }
            return tmp[1]
              ? `${tmp[0][0]} to ${tmp[1][0]}${
                  tmp[0][1] ? ` ${tmp[0][1]}` : ""
                }`
              : `${tmp[0][0]}${tmp[0][1] ? ` ${tmp[0][1]}` : ""}`;
          }),
          dateData[i].ds_lc?.replaceAll("|", ", ")?.replaceAll("#", " "),
          dateData[i].ds_accuracy?.replaceAll("|", ", ")?.replaceAll("#", " "),
          dateData[i].ds_serialNumber,
          dateData[i].ds_DUCID,
          dateData[i].ds_make,
          dateData[i].ds_model,
          dateData[i].ds_calibrationDate
            ? moment(dateData[i].ds_calibrationDate).format("DD-MM-YYYY")
            : "",
          dateData[i].ds_nextDueDate
            ? moment(dateData[i].ds_nextDueDate).format("DD-MM-YYYY")
            : ""
        )
      );
    }
    setCalData(rowData);
    setIds(idAr);
  };
  useEffect(() => {
    getCustomer();
    getData();
  }, []);
  useEffect(() => {
    initializeRowTable();
  }, [dateData]);
  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <Grid container spacing={2} justifyContent={"end"}>
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={pushArray}
              disableCloseOnSelect
              multiple
              renderInput={(params) => <TextField {...params} label="Client" />}
              onChange={(event, value) => {
                // console.log("value",value)
                setCompany(value);
                setCompany([...(value?.map((e) => e.id) || [])]);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="Start Date"
              label="Date From"
              variant="outlined"
              type="date"
              className="mr-2"
              required={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              id="Start Date"
              label="Date To"
              variant="outlined"
              type="date"
              className="mr-2"
              required={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Grid>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              FilterData();
            }}
            style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
          >
            Search
          </Button>
          {ids.length ? (
            // <Button
            //   variant="contained"
            //   size="small"
            //   sx={{ m: 0 }}
            //   component={Link}
            //   to={`/summaryReport/viewSummary/${ids.toString()}`}
            //   style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
            // >
            //   Print Summary
            // </Button>
            <div style={{ marginTop: "14px", marginRight: "15px" }}>
              <ExcelDownload
                finalData={calData}
                notNeededColumn={notNeededColumn}
              />
            </div>
          ) : (
            ""
          )}
        </Grid>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {calData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        let value = row[column.id];

                        if (column.id == "id") {
                          value = page * rowsPerPage + index + 1;
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={calData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </>
  );
}
