import { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Tooltip,
} from "@mui/material";
import { useParams } from "react-router";
import { createDepartmentGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";



export default function CreateAndEditDepartment(props) {
  const createdepartmentGuides = createDepartmentGuide();
  const [departmentName, setDepartmentName] = useState("");
  const params = useParams();
  const [errormodalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const onSubmit = () => {
    var jsonData = {
      name: departmentName,
    };
    let url = BASE_URL;
    (() =>
      params.id != 0
        ? axiosWithToken.patch(url + `departmentMaster/${params.id}`, jsonData)
        : axiosWithToken.post(url + "departmentMaster", jsonData))()
      .then((res) => {
        props.setLoader(false);
        params.id != 0
          ? toast("Department Update!")
          : toast("New Department Added!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        props.setLoader(false);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "onSubmit" ,"create/edit Department")}><u>Error: {err.message}</u></h6>);
      });
  };

  const getDepartmentName = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `departmentMaster/${params.id}`)
      .then((res) => {
        setDepartmentName(res.data[0].name);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getDepartmentName" ,"create/edit Department")}><u>Error: {err.message}</u></h6>);
      });
  };

  const refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id != 0) {
      getDepartmentName();
    }
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          {params.id != 0 ? "Update Department" : "Create New Department"}
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              createdepartmentGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <TextField
            id="department_create_department"
            label="Department name *"
            size="small"
            fullWidth
            value={departmentName || ""}
            variant="outlined"
            onChange={(e) => setDepartmentName(e.target.value)}
          />
        </Grid>
      </Grid>
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          id="department_create_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            if (departmentName) {
              props.setLoader(true);
              onSubmit();
            } else {
              toast.error("department name compulsory");
            }
          }}
        >
          {params.id != 0 ? "Update" : "Save"}
        </Button>
      </Toolbar>

      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </Paper>
  );
}
