import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import {
  TextField,
  Autocomplete,
  Table,
  useMediaQuery,
  Grid,
  Modal,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { clientListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "companyName",
    label: "Client",
    align: "left",
    minWidth: 40,
  },
  {
    id: "address",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "branch",
    label: "Branch",
    align: "left",
    minWidth: 40,
  },
  {
    id: "gstNumber",
    label: "GST Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "state",
    label: "State",
    align: "left",
    minWidth: 40,
  },
  {
    id: "email",
    label: "Email",
    align: "left",
    minWidth: 40,
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   align: "left",
  //   minWidth: 40,
  // },
];

function createData(
  id,
  companyName,
  // contact,
  address,
  branch,
  gstNumber,
  state,
  email,
  status
) {
  return {
    id,
    companyName,
    // contact,
    address,
    branch,
    gstNumber,
    state,
    email,
    status,
  };
}

export default function ClientsList() {
  const clientListGuides = clientListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [clientList, setClientsList] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowDaata, setRowData] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axiosWithToken
      .patch(url + `clients/${id}`, jsonData)
      .then((res) => {
        toast("client was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "c.companyName",
        Address: "a.address",
        Branch: "b.branchName",
        State: "c.state",
      }[searchBy];
      whereConditions = `and ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` lastModified between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT c.id AS id, c.companyName AS companyName, GROUP_CONCAT(a.address SEPARATOR ' || ') AS address, b.branchName AS branch, c.gstNumber AS gstNumber, c.state AS state, c.email AS email, c.status AS status FROM clients AS c LEFT JOIN branch AS b ON c.branch = b.id LEFT JOIN addresses AS a ON FIND_IN_SET(a.id, REPLACE(REPLACE(REPLACE(c.address, ',', '|'), '|', ', '), '|', '')) > 0 WHERE c.status = 1  ${whereConditions} GROUP BY c.id  order by c.id DESC ${pagination_settings}`,
    };
    if (count)
      data.query = `select count(c.id) no_of_rows from clients as c left join branch as b on c.branch = b.id where c.status = 1  ${whereConditions} order by c.id DESC ${pagination_settings}`;
    return data;
  };

  function getClientList() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setClientsList([...res.data]);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  // api calls
  function getTotalRows() {
    let data = getQuery(true);
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const search = () => {
    getClientList();
  };

  useEffect(() => {
    getTotalRows();
  }, []);

  useEffect(() => {
    getClientList();
  }, [rowsPerPage, page]);

  const generateRowData = (clientList) => {
    const newRowData = clientList.map((client) => {
      return createData(
        client.id,
        client.companyName,
        client.address,
        client.branch,
        client.gstNumber,
        client.state,
        client.email,
        client.status
      );
    });

    setRowData(newRowData);
  };

  const copyClient = async (id) => {
    let clientsData = await axiosWithToken
      .get(BASE_URL + `clients/${id}`)
      .then((res) => res.data[0] || []);
    delete clientsData.id;
    delete clientsData.lastModified;

    axiosWithToken.post(BASE_URL + "clients", clientsData).then((res) => {
      setTimeout(refresh, 500);
    });
  };

  useEffect(() => {
    generateRowData(clientList);
  }, [clientList]);

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 5px", marginBottom: "2px" }}
    >
      <Grid item xs={12} sm={5} md={2} lg={2} id="client_list_searchby">
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          size="small"
          value={searchBy}
          options={[
            { key: "companyName", label: "Client" },
            { key: "address", label: "Address" },
            { key: "branch", label: "Branch" },
            { key: "state", label: "State" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
          id="client_list_searchbytext"
          label={"enter " + searchBy}
          value={searchKey}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} textAlign={"left"}>
        <Button
          id="client_list_searchbtn"
          variant="contained"
          size="small"
          onClick={() => {
            search();
            handleFilterClose();
          }}
        >
          <SearchIcon />
        </Button>
      </Grid>
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <Box sx={{ margin: "0px 5px", marginTop: "-0.8rem" }}>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          <Button
            id="client_list_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/client/createClient"
          >
            <b>CREATE CLIENT</b>
          </Button>
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                clientListGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </Box>
      </div>
      {!isMobile && filterSection()}
      <TableContainer sx={{ maxHeight: isMobile ? "80vh" : "65vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="client_list_table"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowDaata.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Client" placement="top-start">
                        <Button
                          id="client_list_edit"
                          component={Link}
                          to={`/master/editClient/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Copy Client" placement="top-start">
                        <Button
                          onClick={() => {
                            if (
                              window.confirm(
                                "Do want to copy and create new Client?"
                              )
                            ) {
                              copyClient(row.id);
                            }
                          }}
                        >
                          <ContentCopyIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Client" placement="top-start">
                        <Button
                          id="client_list_delete"
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete client?")
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
    </Box>
  );
}
