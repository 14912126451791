import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import moment from "moment";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, TablePagination, Button, TextField, Autocomplete } from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useTheme } from "@mui/material/styles";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";

const AttendanceList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [AttendancData, setAttendancData] = useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const FetchQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "User Name": "u.userName",
        "Address": "adr.address",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` a.checkinDatetime between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    // if (whereConditions === "") {
    //   whereConditions = " where ";
    // } else {
    //   whereConditions += " and ";
    // }

    // whereConditions += " qt.status = 1 ";

    let data = {
      query: `SELECT a.*, u.userName, adr.address FROM attendance a JOIN users u ON a.userId = u.id LEFT JOIN addresses adr ON a.addressId = adr.id ${whereConditions}  ORDER BY a.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(a.id) as no_of_rows FROM attendance a JOIN users u ON a.userId = u.id LEFT JOIN addresses adr ON a.addressId = adr.id ${whereConditions} ORDER BY a.id DESC ${pagination_settings}`;
    return data;
  };

  const fetchData = () => {
    const sqlQuery = FetchQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, sqlQuery)
      .then((res) => {
        setAttendancData(res?.data);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchData" ,"AttendanceList")}><u>Error: {err.message}</u></h6>);
      });
  };

  const getTotalRows =()=> {

    const sqlQuery = FetchQuery("count");
    axiosWithToken
      .post(BASE_URL + `dynamic`, sqlQuery)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("Row data fetching error: ", err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getTotalRows" ,"AttendanceList")}><u>Error: {err.message}</u></h6>);
      });
  }

  const search = () => {
    fetchData();
    getTotalRows()
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchData();
    getTotalRows()
  }, [rowsPerPage, page]);

  
  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  return (
    <div>

      <TableContainer component={Paper} sx={{ p:2 }}>

      <br />
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end" }}
      >
        <div style={{ marginLeft: "10px", marginRight: "10px" }} id="quotationlist_datefrom">
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }} id="quotationlist_dateto">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="quotationlist_searchby"
          options={[
            { key: "u.userName", label: "User Name" },
            { key: "adr.address", label: "Address" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="quotationlist_searchbytext"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
          sx={{marginLeft:"10px"}}
        />
        <Button
        id="quotationlist_searchbtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
      </div>
      <br />
      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="quotationlist_table"
        width="100%"
      >
          <TableHead>
            <TableRow>
      
              <TableCell>User Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell> CheckIn Image</TableCell>
              <TableCell>CheckIn Area</TableCell>      
              <TableCell>CheckIn Date Time</TableCell>
              <TableCell> CheckOut Image</TableCell>
              <TableCell>CheckOut Area</TableCell>      
              <TableCell>CheckOut Date Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AttendancData?.map((row, index) => (
              <TableRow key={row.id}>
        
                <TableCell>{row?.userName}</TableCell>
                <TableCell>{row?.address}</TableCell>
                <TableCell><Avatar src={row?.checkinPhotoPath} /></TableCell>
                <TableCell>{`${row?.checkinLat ? `${row?.checkinLat},` : "" } ${row?.checkinLang || ""}`}</TableCell>
                <TableCell>{row?.checkinDatetime ? new Date(row?.checkinDatetime).toLocaleString() : ""}</TableCell>
                <TableCell><Avatar src={row?.checkoutPhoto} /></TableCell>
                <TableCell>{`${row?.checkoutLat ? `${row?.checkoutLat},` : ""} ${row.checkoutLang || ""}`}</TableCell>
                <TableCell>{row?.checkoutDatetime ? new Date(row?.checkoutDatetime).toLocaleString() : ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      </TableContainer>
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </div>
  );
};

export default AttendanceList;
