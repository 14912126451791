import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../global";
import SendTimeExtensionIcon from "@mui/icons-material/SendTimeExtension";
import Tooltip from "@mui/material/Tooltip";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No." },
  { id: "customerName", label: "Client", minWidth: 40 },
  { id: "supplyNo", label: "Supply No", minWidth: 40 },
  { id: "dateOfPurchase", label: "Date Of Purchase", minWidth: 40 },
  { id: "amount", label: "Total Amount", minWidth: 40 },
];

function createData(id, customerName, supplyNo, dateOfPurchase, amount) {
  return {
    id,
    customerName,
    supplyNo,
    dateOfPurchase,
    amount,
  };
}

export default function PurchaseList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [standardList, setStandardList] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axiosWithToken
      .patch(url + `purchase/${id}`, jsonData)
      .then((res) => {
        toast("updated standard!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getStandardList = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          "xjoin?_join=pr.purchase,_j,client.clients&_on1=(pr.clientId,eq,client.id)&_fields=pr.id,pr.supplyNo,pr.dateOfPurchase,client.companyName,pr.readings"
      )
      .then((res) => {
        setStandardList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  useEffect(() => {
    getStandardList();
  }, []);

  var rowDaata = [];
  var counter = 0;
  for (let i = 0; i < standardList.length; i++) {
    var jData = JSON.parse(standardList[i].pr_readings);
    for (let i = 0; i < jData.length; i++) {
      counter += jData[i].amount;
    }
    rowDaata.push(
      createData(
        standardList[i].pr_id,
        standardList[i].client_companyName,
        standardList[i].pr_supplyNo,
        standardList[i].pr_dateOfPurchase != null
          ? moment(standardList[i].pr_dateOfPurchase).format("DD-MM-YYYY")
          : "",
        counter
      )
    );
  }

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/purchase/createPurchase"
        >
          <b>ADD NEW PURCHASE</b>
        </Button>
      </Toolbar>
      <hr />

      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDaata
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Purchase" placement="top-start">
                        <Button
                          style={{
                            borderColor: "#dc3545",
                          }}
                          component={Link}
                          to={`/master/purchase/editPurchase/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Purchase" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete purchase?")
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                          style={{
                            borderColor: "#dc3545",
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowDaata.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
